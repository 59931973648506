import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";

type SetPendingMaterialCustomNameParams = {
  index: number;
  name: string;
};

function SetPendingMaterialCustomNameReducer(
  state: ProposalState,
  {
    payload: { index, name },
  }: PayloadAction<SetPendingMaterialCustomNameParams>,
) {
  if (
    state.pendingMaterials[index] &&
    state.pendingMaterials[index].customCommercialConditions
  ) {
    state.pendingMaterials[index].customCommercialConditions!.name = name;
  } else {
    state.pendingMaterials[index].customCommercialConditions = {
      name: name,
      price: "",
      unitsNb: "",
    };
  }
}
export default SetPendingMaterialCustomNameReducer;
