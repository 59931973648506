import React, { useMemo, useCallback } from "react";

// Components
import { Link } from "react-router-dom";
import { Icon } from "@france/superelements/lib";
import { useLocalize } from "@france/superelements/utils/lib";
import { IonFooter, useIonRouter } from "@ionic/react";

// Style & Configs
import "./MainFooter.scss";
import { footerConfig, FooterConfigType } from "../../constants/footerConfig";
import ROUTES from "../../constants/routes";
import { trackEvent } from "../../../services/applicationInsight";

const FooterItem: React.FC<FooterConfigType> = ({
  icon,
  path,
  disabled,
  label,
  trackingName,
  trackingParams,
}) => {
  const t = useLocalize();
  const { routeInfo } = useIonRouter();

  const isCurrent = useMemo(() => {
    if (path) {
      if (
        (routeInfo.pathname === "/" && path === ROUTES.HOME) ||
        routeInfo.pathname.includes(path)
      ) {
        return true;
      }
      return false;
    }
  }, [path, routeInfo.pathname]);

  const handleClick = useCallback(() => {
    if (trackingName) trackEvent(trackingName, trackingParams);
  }, [trackingName, trackingParams]);

  if (disabled)
    return (
      <li>
        <Icon icon={icon} />
        <span>{t(label)}</span>
      </li>
    );

  return (
    <Link
      to={path || "/"}
      role="listitem"
      className={isCurrent ? "currentPage" : undefined}
      onClick={handleClick}
    >
      <Icon icon={icon} />
      <span>{t(label)}</span>
    </Link>
  );
};

const MainFooter: React.FC<any> = () => {
  return (
    <IonFooter id="MainFooter">
      <nav>
        <ul>
          {footerConfig.map((item) => (
            <FooterItem {...item} key={item.label} />
          ))}
        </ul>
      </nav>
    </IonFooter>
  );
};

export default MainFooter;
