import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";

type SetPendingMaterialInformationParams = {
  index: number;
  productId: number;
  packagedProductId: number;
};

function SetPendingMaterialInformationReducer(
  state: ProposalState,
  {
    payload: { index, productId, packagedProductId },
  }: PayloadAction<SetPendingMaterialInformationParams>,
) {
  state.pendingMaterials[index].formId = `${productId}`;
  state.pendingMaterials[index].productId = productId;
  state.pendingMaterials[index].commercialConditions.packagedProductId =
    packagedProductId;
}

export default SetPendingMaterialInformationReducer;
