import {
  ProposalFragment,
  ProposalStates,
  useGetPosProposalsLastLazyQuery,
} from "../../gql/hooks";
import { useLocalize } from "@france/superelements/utils";
import { useCallback, useEffect, useMemo, useState } from "react";

import { ButtonHighlight, Icon } from "@france/superelements/lib";
import { IonModal, useIonRouter } from "@ionic/react";

import "./ProposalDraftModal.scss";
import ROUTES from "../../router/constants/routes";
import { formatDateFourDigits } from "../../utils/dateTime";

const ProposalDraftModal: React.FC<{
  posId: number;
  openStatus: boolean;
  onClose: () => void;
  redirect?: boolean;
  onNew?: () => void;
  onOld?: (id?: number) => void;
}> = ({ posId, openStatus, redirect, onClose, onNew, onOld }) => {
  // Hooks
  const t = useLocalize();
  const { push } = useIonRouter();
  const [loadLastProposal] = useGetPosProposalsLastLazyQuery();

  // States
  const [draftProposal, setDraftProposal] = useState<ProposalFragment | null>(
    null,
  );

  // Memos
  const recapStep = useMemo(() => {
    let step = 1;
    if (!!draftProposal?.expireAt) {
      ++step;
      if (!!draftProposal?.brandId) {
        ++step;
      }
    }
    return step;
  }, [draftProposal?.brandId, draftProposal?.expireAt]);

  // Handlers
  const handleClose = useCallback(() => {
    onClose();
    setDraftProposal(null);
  }, [onClose]);

  const handleNew = useCallback(() => {
    if (redirect) {
      localStorage.setItem("reachableCreationStep", "1");
      localStorage.setItem("selectedPosId", posId.toString());
      push(`${ROUTES.RECOMMENDED_ASSORTMENT}`);
    }
    onNew?.();
    handleClose();
  }, [handleClose, onNew, posId, push, redirect]);

  const handleOld = useCallback(() => {
    if (redirect) {
      localStorage.setItem("proposalId", `${draftProposal?.id}`);
      push(`${ROUTES.RECOMMENDED_ASSORTMENT}`);
    }
    onOld?.(draftProposal?.id);
    handleClose();
  }, [draftProposal?.id, handleClose, onOld, push, redirect]);

  const handleJudge = useCallback(async () => {
    const { data } = await loadLastProposal({
      variables: { posIds: [posId], take: 1, skip: 0 },
      fetchPolicy: "network-only",
    });

    if (data?.proposals?.[0]?.state === ProposalStates.Draft) {
      setDraftProposal(data?.proposals?.[0]);
    } else {
      handleNew();
    }
  }, [handleNew, loadLastProposal, posId]);

  // Effect
  useEffect(() => {
    if (openStatus && posId) {
      handleJudge();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openStatus]);

  return (
    <IonModal
      id="ProposalDraftModal"
      isOpen={!!draftProposal}
      onWillDismiss={handleClose}
    >
      {!!draftProposal && (
        <div className="container">
          <div className="head">{t("draft_exists")}</div>
          <div className="content">
            <span>{t("draft_exists_desc")}</span>
            <div className="box">
              <div className="title">
                <Icon icon="document" />
                <span>{draftProposal?.title}</span>
              </div>
              <div className="desc">
                <div className="recapStep">{recapStep}</div>
                <div className="text">{t("proposal_recap")}</div>
                <div className="date">
                  {draftProposal.createdAt &&
                    formatDateFourDigits(draftProposal.createdAt)}
                </div>
              </div>
            </div>
            <div className="warn">
              <Icon icon="info" />
              <span>{t("draft_override")}</span>
            </div>
          </div>
          <div className="footer">
            <ButtonHighlight name={t("create_new")} onClick={handleNew} />
            <ButtonHighlight name={t("take_over")} onClick={handleOld} />
          </div>
        </div>
      )}
    </IonModal>
  );
};

export default ProposalDraftModal;
