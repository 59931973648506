import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  ApolloLink,
  NormalizedCacheObject,
  createHttpLink,
  split,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";

// Methods
import { SentryLink } from "../sentry/ApolloLink";

// Constants
import cache from "./cache";
import { createClient } from "graphql-ws";
import OktaRequest from "../../utils/security/Okta";
import { getCookie } from "../../utils/security/cookies";
import { getMainDefinition } from "@apollo/client/utilities";
import { TOKEN_KEY } from "@france/superelements/utils/constants/auth";

const pEnv = (window as any)["env"]?.REACT_APP_GRAPHQL_URL
  ? (window as any)["env"]
  : process.env;

const GRAPH_URL = pEnv.REACT_APP_GRAPHQL_URL;

const httpLink = createHttpLink({
  uri: GRAPH_URL,
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const token = await getCookie(TOKEN_KEY);
    if (!token) {
      throw new Error("not already logged");
    }
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  } catch (e) {
    return { headers };
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path: errorPath }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${errorPath}`,
      );
    });

    const hasUnauth = graphQLErrors.some(
      (e) =>
        e.message === "Unauthorized" ||
        e.message === "GqlAuthGuard" ||
        e.message === "Forbidden resource",
    );
    if (hasUnauth) {
      new OktaRequest().logout();
    }
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const namedLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    uri: `${GRAPH_URL}?${operation.operationName}`,
  }));
  return forward ? forward(operation) : null;
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: GRAPH_URL?.replace("http", "ws"),
  }),
);

// Sentry
const sentryLink = new SentryLink({
  attachBreadcrumbs: {
    includeVariables: true,
    includeFetchResult: true,
    includeError: true,
  },
});

export default async function initApollo(
  setClient: React.Dispatch<
    React.SetStateAction<ApolloClient<NormalizedCacheObject> | undefined>
  >,
) {
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    ApolloLink.from([namedLink, errorLink, sentryLink, httpLink]),
  );

  setClient(
    new ApolloClient({
      connectToDevTools: true,
      cache,
      link: authLink.concat(splitLink),
    }),
  );
}
