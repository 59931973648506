import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";
import { constructProductsCommercialCondition } from "../../../../utils/commercialConditions/constructProductCondition";

// Short proposal product conditions
type AddProductConditionsParams = {
  productIds: number[];
};

function AddProductConditionsReducer(
  state: ProposalState,
  { payload: { productIds } }: PayloadAction<AddProductConditionsParams>,
) {
  const formattedProductConditions =
    constructProductsCommercialCondition(productIds);

  formattedProductConditions.forEach((form) => {
    state.productByFormId[form.formId] = form;

    const newFormIds = Object.keys(state.productByFormId);
    state.productFormIds = newFormIds;
  });
}

export default AddProductConditionsReducer;
