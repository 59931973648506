import { Icon, IconType } from "@france/superelements/lib";

export interface ProfileDetailProps {
  value?: string | null;
  icon: IconType;
}

const ProfileDetail: React.FC<ProfileDetailProps> = ({ value, icon }) => {
  return (
    <div className="profileDetail">
      <Icon icon={icon} size="small" />
      <div>{value?.length ? value : "_"}</div>
    </div>
  );
};

export default ProfileDetail;
