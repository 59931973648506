import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";
import { DiscountType } from "../../../../gql/hooks";

type SetProductDiscountTypeParams = {
  formId: string;
  discountType: DiscountType;
};

function SetProductDiscountTypeReducer(
  state: ProposalState,
  {
    payload: { formId, discountType },
  }: PayloadAction<SetProductDiscountTypeParams>,
) {
  state.productByFormId[formId].commercialConditions.discountType =
    discountType;
  // Reset the value of the previous discount as format may be different
  state.productByFormId[formId].commercialConditions.discountValue = "";
  state.productByFormId[formId].commercialConditions.discountX = undefined;
  state.productByFormId[formId].commercialConditions.discountY = undefined;
  const productByformId = state.productByFormId;
  const currentProduct = productByformId[formId];
  const currentContainer = currentProduct.commercialConditions.container;
  if (!currentContainer) return;
  const firstProductOfSameContainer = Object.values(productByformId).find(
    (p) => p.commercialConditions.container === currentContainer,
  );
  if (firstProductOfSameContainer?.formId === formId) {
    Object.values(productByformId)
      .filter(
        ({ commercialConditions }) =>
          commercialConditions.container === currentContainer,
      )
      .filter(({ commercialConditions }) => !commercialConditions.discountValue)
      .filter(({ commercialConditions }) => !commercialConditions.discountX)
      .filter(({ commercialConditions }) => !commercialConditions.discountY)
      .forEach((p) => {
        state.productByFormId[p.formId].commercialConditions.discountType =
          discountType;
      });
  }
}

export default SetProductDiscountTypeReducer;
