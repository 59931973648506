import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";
import { ProductConditionFormType } from "../types/productConditionTypes";

type RemoveProductConditionFormParams = {
  formIds: string[];
};

function RemoveProductConditionsReducer(
  state: ProposalState,
  { payload: { formIds } }: PayloadAction<RemoveProductConditionFormParams>,
) {
  const updatedProductFormIds = state.productFormIds.filter(
    (productFormId) => !formIds.includes(productFormId),
  );
  const newProductByFormId = state.productByFormId;
  const updatedProductByFormId = Object.keys(newProductByFormId).reduce(
    (acc: { [key: string]: ProductConditionFormType }, key) => {
      if (!formIds.includes(key)) {
        acc[key] = newProductByFormId[key];
      }
      return acc;
    },
    {},
  );
  state.productFormIds = updatedProductFormIds;
  state.productByFormId = updatedProductByFormId;
}

export default RemoveProductConditionsReducer;
