import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";

type SetPendingMaterialCustomUnitParams = {
  index: number;
  unitsNb: string;
};

function SetPendingMaterialCustomUnitReducer(
  state: ProposalState,
  {
    payload: { index, unitsNb },
  }: PayloadAction<SetPendingMaterialCustomUnitParams>,
) {
  if (
    state.pendingMaterials[index] &&
    state.pendingMaterials[index].customCommercialConditions
  ) {
    state.pendingMaterials[index].customCommercialConditions!.unitsNb = unitsNb;
  } else {
    state.pendingMaterials[index].customCommercialConditions = {
      name: "",
      price: "",
      unitsNb: unitsNb,
    };
  }
}
export default SetPendingMaterialCustomUnitReducer;
