import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";

type SetPendingMaterialCustomPriceParams = {
  index: number;
  price: string;
};

function SetPendingMaterialCustomPriceReducer(
  state: ProposalState,
  {
    payload: { index, price },
  }: PayloadAction<SetPendingMaterialCustomPriceParams>,
) {
  if (
    state.pendingMaterials[index] &&
    state.pendingMaterials[index].customCommercialConditions
  ) {
    state.pendingMaterials[index].customCommercialConditions!.price = price;
  } else {
    state.pendingMaterials[index].customCommercialConditions = {
      name: "",
      price: price,
      unitsNb: "",
    };
  }
}
export default SetPendingMaterialCustomPriceReducer;
