import AuthDevProvider from "../AuthDevProvider/AuthDevProvider";
import AuthProvider from "../AuthProvider/AuthProvider";

const pEnv = (window as any)["env"]?.REACT_APP_GRAPHQL_URL
  ? (window as any)["env"]
  : process.env;

const AuthChecker: React.FC<{ children: any }> = ({ children }) => {
  if (pEnv.REACT_APP_AUTH0_DOMAIN) {
    return <AuthDevProvider>{children}</AuthDevProvider>;
  }
  return <AuthProvider>{children}</AuthProvider>;
};

export default AuthChecker;
