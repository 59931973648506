import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";

type SetCustomProductPriceParams = {
  formId: string;
  value: string;
};

function SetCustomProductPriceReducer(
  state: ProposalState,
  { payload: { formId, value } }: PayloadAction<SetCustomProductPriceParams>,
) {
  state.productByFormId[formId].commercialConditions.customPrice = value;
}

export default SetCustomProductPriceReducer;
