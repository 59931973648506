import { LocalizeConfig, COOKIE_LANG } from "@france/superelements/utils";
import { useCallback, useState } from "react";
import { AVAILABLE_LANG } from "../../../locales";
import { getCookie, setCookie } from "../../../utils/security/cookies";

const LocalizeProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [currentLang, setLanguage] = useState(
    `${getCookie(COOKIE_LANG) || "es"}`,
  );

  const handleLang = useCallback((lang: string) => {
    setCookie(COOKIE_LANG, lang);
    setLanguage(lang);
  }, []);

  const values = {
    defaultLang: "es",
    currentLang,
    setLanguage: handleLang,
    availableLangs: AVAILABLE_LANG,
  };

  return (
    <LocalizeConfig.Provider value={values}>{children}</LocalizeConfig.Provider>
  );
};

export default LocalizeProvider;
