import { configureStore } from "@reduxjs/toolkit";
import posFiltersReducer from "./slices/posFilters";
import proposalReducer from "./slices/proposal";

const reducer = {
  posFilters: posFiltersReducer,
  proposal: proposalReducer,
};

export const store = configureStore({
  reducer,
  devTools: true, //process.env.NODE_ENV !== 'production',
});

export type Dispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
