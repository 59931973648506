import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();

const pEnv = (window as any)["env"]?.REACT_APP_AZURE_APP_INSIGHT
  ? (window as any)["env"]
  : process.env;

const appInsights = new ApplicationInsights({
  config: {
    connectionString: pEnv.REACT_APP_AZURE_APP_INSIGHT,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

export function trackPage(path: string) {
  appInsights.trackPageView({ name: path });
}

export function trackUserLogged(userId: string) {
  appInsights.setAuthenticatedUserContext(userId);
}

export function trackEvent(name: string, params: any = undefined) {
  appInsights.trackEvent({ name, properties: params });
}
