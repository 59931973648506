import React from "react";

// Components
import DesktopMenuItem from "./partials/DesktopMenuItem";
import NewProposalButton from "./partials/NewProposalButton";

// Style & Configs
import "./DesktopMenu.scss";
import { footerConfig } from "../../constants/footerConfig";

const DesktopMenu: React.FC = () => {
  return (
    <div className="side-navigation-menu">
      <menu>
        {footerConfig.map(
          (item) =>
            !item.mobileOnly && <DesktopMenuItem {...item} key={item.label} />,
        )}
      </menu>
      <menu className="bottom">
        <NewProposalButton />
      </menu>
    </div>
  );
};

export default DesktopMenu;
