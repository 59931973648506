import * as cookies from "@france/superelements/utils/security/cookies";
import {
  decryptData,
  encryptData,
} from "@france/superelements/utils/security/encrypt";
import isBrowser from "../isBrowser";

export const CookiePrePath = "PRE_PATH";
/*
 ** Encrypts and sets the cookie.
 ** You can set an expiration date by passing a Date in any format (Timestamp, dd/mm/yyy, Date)
 */
export const setCookie = (
  key: string,
  value: string = "",
  expiration?: string,
) => {
  if (isBrowser()) {
    cookies.setCookie(key, value, expiration);
  } else {
    const valueEnc = encryptData(value);
    localStorage.setItem(key, valueEnc);
  }
};
/*
 ** Gets the desired cookie from the saved cookies
 */
export const getCookie = (key: string) => {
  if (!isBrowser()) {
    const value = localStorage.getItem(key);
    if (!value) return null;
    return decryptData(value);
  } else {
    return cookies.getCookie(key);
  }
};
/*
 ** Deletes the desired cookie from the saved cookies
 */
export const deleteCookie = (key: string) => {
  if (!isBrowser) {
    localStorage.removeItem(key);
  } else {
    cookies.deleteCookie(key);
  }
};
/*
 ** Deletes all the cookies; Cookie monster sad.
 */
export const deleteAllCookies = () => {
  const savedCookie = getCookie(CookiePrePath);
  if (!isBrowser) {
    localStorage.clear();
  } else {
    cookies.deleteAllCookies();
  }
  if (!!savedCookie && typeof savedCookie === "string")
    setCookie(CookiePrePath, savedCookie);
};
