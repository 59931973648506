import { RouteInfo, useIonRouter } from "@ionic/react";
import { FC, useEffect, useState } from "react";
import config from "../router";
import pages from "../router/constants/lazyLoad";

export type ComponentGroup = {
  Component: React.LazyExoticComponent<FC<any>>;
  params: ArgsDict;
};

export type ArgsDict = {
  [arg: string]: string;
};

function findComponent(routeInfo: RouteInfo<any>): ComponentGroup {
  /* contains the pathname params */
  let dict: ArgsDict = {};

  /* tries to find a corresponding route from the config */
  const routeComponent = config.find(({ path, exact }) => {
    /* neutering of the paths to compare them */
    const blankPath = (path.match(/^(\/[\w-]+)/) || [])[1];
    const blankRoutePathname = (routeInfo.pathname.match(/^(\/[\w-]+)/) ||
      [])[1];

    if (
      blankPath &&
      blankRoutePathname &&
      ((!exact && blankRoutePathname.includes(blankPath)) ||
        (exact && blankRoutePathname === blankPath))
    ) {
      /* template arguments in the path (/example/:arg-1/:arg2 > [arg-1, arg2]) */
      const args =
        path
          .match(/((?:\/:[\w-]+)+)/)
          ?.at(1)
          ?.split("/:")
          .filter((arg) => arg) || [];
      /* same as above but for the actual URL */
      const values =
        routeInfo.pathname
          .match(/\/[\w-]+((?:\/[\w-]+)+)/)
          ?.at(1)
          ?.split("/")
          .filter((value) => value) || [];

      if (!exact || (exact && args.length === values.length)) {
        args.forEach((arg, index) => (dict[arg] = values[index]));
        return true;
      }
    }
    return false;
  });

  if (routeComponent)
    return { Component: routeComponent.component, params: dict };
  if (routeInfo.pathname === "" || routeInfo.pathname === "/")
    return { Component: pages.Home, params: dict };

  /* could be a 404 */
  return { Component: pages.Home, params: dict };
}

const useOutlet = () => {
  const { routeInfo } = useIonRouter();
  const [currentComponent, setCurrentComponent] = useState<ComponentGroup>();

  useEffect(() => {
    const newComponent = findComponent(routeInfo);

    if (newComponent !== currentComponent) setCurrentComponent(newComponent);
  }, [routeInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  return currentComponent;
};

export default useOutlet;
