import useWindowSize from "@france/superelements/utils/lib/hooks/useWindowSize";
import { useEffect, useMemo } from "react";

export const XS_WIN_SIZE = 410;
export const MIN_WIN_SIZE = 596;
export const MD_WIN_SIZE = 768;
export const HEALTHY_HALF_SIZE = 1000;

let lastValue = -1;
export const useIsLoading = () => lastValue === -1;

const useMobileSize = (min: number = MIN_WIN_SIZE) => {
  const windowSize = useWindowSize();

  const isMobileSize = useMemo(
    () =>
      !windowSize?.width && lastValue >= 0
        ? lastValue <= min
        : !windowSize?.width || windowSize.width <= min,
    [min, windowSize.width],
  );

  useEffect(() => {
    if (windowSize.width) lastValue = windowSize.width;
  }, [windowSize.width]);

  return isMobileSize;
};

export default useMobileSize;
