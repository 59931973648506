import { useIsomorphicLayoutEffect } from "@france/superelements/utils";
import gsap from "gsap";
import type { Dispatch, FC, SetStateAction } from "react";
import { Suspense, createContext, useContext, useState } from "react";
import SimpleMahouLoader from "../components/SimpleMahouLoader/SimpleMahouLoader";
import { ComponentGroup } from "../hooks/useOutlet";

type TransitionContextValues = {
  timeline: gsap.core.Timeline;
  setTimeline: Dispatch<SetStateAction<gsap.core.Timeline>>;
};

interface TransitionLayoutProps {
  className?: string;
  component: ComponentGroup | undefined;
}

const WRAPPED_LOADER = (
  <div className="wrappedLoader">
    <SimpleMahouLoader />
  </div>
);

const EMPTY_ARGS = {};

/* the context used to get the timeline for setup the outro */
// @ts-ignore
const TransitionContext = createContext<TransitionContextValues>({});

/* the provider used with the context */
const TransitionProvider = (props: { children: any }) => {
  const [timeline, setTimeline] = useState<gsap.core.Timeline>(
    gsap.timeline({ paused: true }),
  );

  return (
    <TransitionContext.Provider
      value={{
        timeline,
        setTimeline,
      }}
    >
      {props.children}
    </TransitionContext.Provider>
  );
};

/* the transition layout serves the new page by executing the outro transition stacked in the context timeline if there is */
const TransitionLayout: React.FC<TransitionLayoutProps> = ({ component }) => {
  const [DisplayChildren, setDisplayChildren] =
    useState<React.LazyExoticComponent<FC<any>>>();
  const { timeline } = useContext(TransitionContext);

  useIsomorphicLayoutEffect(() => {
    if (component?.Component) {
      const Component = component?.Component;
      if (timeline.duration() === 0) {
        // there are no outro animations, so immediately transition
        setDisplayChildren(Component);
      } else {
        timeline.play(0).then(() => {
          // outro complete so reset to an empty paused timeline
          timeline.clear(true).pause(0);
          setDisplayChildren(Component);
        });
      }
    }
  }, [component?.Component]);

  return (
    <div id="CustomOutlet">
      <Suspense fallback={WRAPPED_LOADER}>
        {DisplayChildren && (
          <DisplayChildren params={component?.params || EMPTY_ARGS} />
        )}
      </Suspense>
    </div>
  );
};

export { TransitionContext, TransitionLayout, TransitionProvider };
