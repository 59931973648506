import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";

type SetProductDiscountYParams = {
  formId: string;
  value: string;
};

function SetProductDiscountYReducer(
  state: ProposalState,
  { payload: { formId, value } }: PayloadAction<SetProductDiscountYParams>,
) {
  state.productByFormId[formId].commercialConditions.discountY = value;
}

export default SetProductDiscountYReducer;
