import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";
import { RecallType } from "../../../../gql/hooks";

type SetProductRecallTypeParams = {
  formId: string;
  recallType: RecallType;
};

function SetProductRecallTypeReducer(
  state: ProposalState,
  {
    payload: { formId, recallType },
  }: PayloadAction<SetProductRecallTypeParams>,
) {
  state.productByFormId[formId].commercialConditions.recallType = recallType;
  // Reset the value of the previous recall as format may be different
  state.productByFormId[formId].commercialConditions.recallValue = "";
  const productByformId = state.productByFormId;
  const currentProduct = productByformId[formId];
  const currentContainer = currentProduct.commercialConditions.container;
  if (!currentContainer) return;
  const firstProductOfSameContainer = Object.values(productByformId).find(
    (p) => p.commercialConditions.container === currentContainer,
  );
  if (firstProductOfSameContainer?.formId === formId) {
    Object.values(productByformId)
      .filter(
        ({ commercialConditions }) =>
          commercialConditions.container === currentContainer,
      )
      .filter(({ commercialConditions }) => !commercialConditions.recallValue)
      .forEach(({ formId }) => {
        state.productByFormId[formId].commercialConditions["recallType"] =
          recallType;
      });
  }
}

export default SetProductRecallTypeReducer;
