import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";

type SetProductAmortizationValueReducer = {
  formId: string;
  value: string;
};

function SetProductRecallValueReducer(
  state: ProposalState,
  {
    payload: { formId, value },
  }: PayloadAction<SetProductAmortizationValueReducer>,
) {
  state.productByFormId[formId].commercialConditions.amortizationValue = value;
}

export default SetProductRecallValueReducer;
