import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";

type SetProductRecallValueParams = {
  formId: string;
  value: string;
};

function SetProductRecallValueReducer(
  state: ProposalState,
  { payload: { formId, value } }: PayloadAction<SetProductRecallValueParams>,
) {
  state.productByFormId[formId].commercialConditions.recallValue = value;
}

export default SetProductRecallValueReducer;
