import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";

type SetProductPackagedIdParams = {
  formId: string;
  value: number;
  price?: number | null;
};

function SetProductPackagedIdReducer(
  state: ProposalState,
  {
    payload: { formId, value, price },
  }: PayloadAction<SetProductPackagedIdParams>,
) {
  state.productByFormId[formId].commercialConditions.price = price || undefined;
  state.productByFormId[formId].commercialConditions.packagedProductId = value;
}

export default SetProductPackagedIdReducer;
