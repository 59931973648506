import { PayloadAction, createSlice } from "@reduxjs/toolkit";

// Types
import { ProductConditionFormType } from "./types/productConditionTypes";
import {
  MaterialConditionFormType,
  MaterialPendingConditionFormType,
} from "./types/materialConditionTypes";

// Reducers
import SetProductDiscountTypeReducer from "./productReducers/SetProductDiscountTypeReducer";
import SetProductRecallTypeReducer from "./productReducers/SetProductRecallTypeReducer";
import AddProductConditionsByContainerReducer from "./productReducers/AddProductConditionsByContainerReducer";
import AddProductConditionsReducer from "./productReducers/AddProductConditionsReducer";
import RemoveProductConditionsReducer from "./productReducers/RemoveProductConditionsReducer";
import AddMaterialConditionsReducer from "./materialReducers/AddMaterialConditionsReducer";
import AddPendingMaterialReducer from "./materialReducers/AddPendingMaterialReducer";
import SetPendingMaterialCategoryReducer from "./materialReducers/SetPendingMaterialCategoryReducer";
import SetPendingMaterialInformationReducer from "./materialReducers/SetPendingMaterialInformationReducer";
import SetPendingMaterialUnitReducer from "./materialReducers/SetPendingMaterialUnitReducer";
import RemovePendingMaterialReducer from "./materialReducers/RemovePendingMaterialReducer";
import RemoveMaterialConditionsReducer from "./materialReducers/RemoveMaterialConditionsReducer";
import SetMaterialPackagedIdReducer from "./materialReducers/SetMaterialPackagedIdReducer";
import SetMaterialConditionUnitReducer from "./materialReducers/SetMaterialConditionUnitReducer";
import SetProductRecallValueReducer from "./productReducers/SetProductRecallValueReducer";
import SetProductAmortizationTypeReducer from "./productReducers/SetProductAmortizationTypeReducer";
import SetProductAmortizationValueReducer from "./productReducers/SetProductAmortizationValueReducer";
import SetProductDiscountYReducer from "./productReducers/SetProductDiscountYReducer";
import SetProductPackagedIdReducer from "./productReducers/SetProductPackagedIdReducer";
import SetProductContainerReducer from "./productReducers/SetProductContainerReducer";
import SetProductUnitReducer from "./productReducers/SetProductUnitReducer";
import SetProductDiscountXReducer from "./productReducers/SetProductDiscountXReducer";
import SetProductDiscountValueReducer from "./productReducers/SetProductDiscountValueReducer";
import RemovePendingProductReducer from "./productReducers/RemovePendingProductReducer";
import ClearPendingReducer from "./materialReducers/ClearPendingReducer";
import SetCustomProductPriceReducer from "./productReducers/SetCustomProductPriceReducer";
import SetPendingMaterialCustomNameReducer from "./materialReducers/SetPendingMaterialCustomNameReducer";
import SetPendingMaterialCustomPriceReducer from "./materialReducers/SetPendingMaterialCustomPriceReducer";
import SetPendingMaterialCustomUnitReducer from "./materialReducers/SetPendingMaterialCustomUnitReducer";
import SetProductFreeUnitReducer from "./productReducers/SetProductFreeUnitReducer";

type SetProposalInfoParams = {
  proposalId: number;
  brandId: number;
};

export interface ProposalState {
  proposalId?: number;
  brandId?: number;
  productFormIds: string[];
  materialFormIds: string[];
  productByFormId: {
    [formId: string]: ProductConditionFormType;
  };
  materialByFormId: {
    [formId: string]: MaterialConditionFormType;
  };
  pendingMaterials: MaterialPendingConditionFormType[];
}

export const initialState: ProposalState = {
  proposalId: undefined,
  brandId: undefined,
  productFormIds: [],
  productByFormId: {},
  materialFormIds: [],
  materialByFormId: {},
  pendingMaterials: [],
};

const slice = createSlice({
  name: "proposal",
  initialState,
  reducers: {
    // PROPOSAL
    SetProposalInfo(
      state: ProposalState,
      {
        payload: { proposalId, brandId },
      }: PayloadAction<SetProposalInfoParams>,
    ) {
      state.proposalId = proposalId;
      state.brandId = brandId;
    },

    // PRODUCT CONDITIONS
    SetProductUnit: SetProductUnitReducer,
    SetProductFreeUnit: SetProductFreeUnitReducer,
    SetProductContainer: SetProductContainerReducer,
    SetProductPackagedId: SetProductPackagedIdReducer,
    SetProductRecallValue: SetProductRecallValueReducer,
    SetProductAmortizationValue: SetProductAmortizationValueReducer,
    SetProductDiscountValue: SetProductDiscountValueReducer,
    SetProductDiscountY: SetProductDiscountYReducer,
    SetProductDiscountX: SetProductDiscountXReducer,
    SetProductDiscountType: SetProductDiscountTypeReducer,
    SetProductRecallType: SetProductRecallTypeReducer,
    SetProductAmortizationType: SetProductAmortizationTypeReducer,
    SetCustomProductPrice: SetCustomProductPriceReducer,
    AddProductConditionsByContainer: AddProductConditionsByContainerReducer,
    AddProductConditions: AddProductConditionsReducer,
    RemoveProductConditions: RemoveProductConditionsReducer,
    RemovePendingProduct: RemovePendingProductReducer,

    // MATERIAL CONDITIONS
    SetMaterialConditionUnit: SetMaterialConditionUnitReducer,
    SetMaterialPackagedId: SetMaterialPackagedIdReducer,
    AddMaterialConditions: AddMaterialConditionsReducer,
    RemoveMaterialConditions: RemoveMaterialConditionsReducer,
    AddPendingMaterial: AddPendingMaterialReducer,
    SetPendingMaterialCategory: SetPendingMaterialCategoryReducer,
    SetPendingMaterialInformation: SetPendingMaterialInformationReducer,
    SetPendingMaterialCustomName: SetPendingMaterialCustomNameReducer,
    SetPendingMaterialCustomPrice: SetPendingMaterialCustomPriceReducer,
    SetPendingMaterialCustomUnit: SetPendingMaterialCustomUnitReducer,
    SetPendingMaterialUnit: SetPendingMaterialUnitReducer,
    RemovePendingMaterial: RemovePendingMaterialReducer,
    ClearPending: ClearPendingReducer,

    ResetProposalState() {
      return initialState;
    },
  },
});

export const {
  // PROPOSAL
  SetProposalInfo,

  // PRODUCT CONDITIONS
  SetProductContainer,
  SetProductDiscountValue,
  SetProductDiscountX,
  SetProductDiscountY,
  SetProductPackagedId,
  SetProductRecallValue,
  SetProductAmortizationValue,
  SetProductUnit,
  SetProductFreeUnit,
  SetProductDiscountType,
  SetProductRecallType,
  SetProductAmortizationType,
  SetCustomProductPrice,
  RemoveProductConditions,
  RemovePendingProduct,
  // For Classic proposal: has preselected container type
  AddProductConditionsByContainer,
  // For Short proposal: has no preselected container type
  AddProductConditions,

  // MATERIAL CONDITIONS
  AddMaterialConditions,
  SetMaterialPackagedId,
  SetMaterialConditionUnit,
  AddPendingMaterial,
  SetPendingMaterialCategory,
  SetPendingMaterialInformation,
  SetPendingMaterialUnit,
  SetPendingMaterialCustomName,
  SetPendingMaterialCustomPrice,
  SetPendingMaterialCustomUnit,
  RemovePendingMaterial,
  RemoveMaterialConditions,
  ClearPending,

  // RESET ALL
  ResetProposalState,
} = slice.actions;

export default slice.reducer;
