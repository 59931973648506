import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { IonContent, IonPage } from "@ionic/react";
import { ButtonHighlight } from "@france/superelements/lib";
import LoadingPage from "../../../pages/LoadingPage/LoadingPage";

import jwtDecode from "jwt-decode";
import ROUTES from "../../constants/routes";

import OktaRequest from "../../../utils/security/Okta";
import { trackUserLogged } from "../../../services/applicationInsight";

import "./AuthProvider.scss";
import { COOKIE_LANG } from "@france/superelements/utils";
import { getCookie } from "../../../utils/security/cookies";
import locales from "../../../locales";

let itDidRun = false;
let wasError = false;

const AuthProvider: React.FC<any> = ({ children }) => {
  const refreshTokenRef = useRef<NodeJS.Timeout | null>(null);

  const [isError, setIsError] = useState(wasError);
  const [appStarted, setAppStarted] = useState(false);

  const t = useMemo(() => {
    const lang = getCookie(COOKIE_LANG) || "es";

    return (value: string) => (locales as any)?.[lang as string]?.[value];
  }, []);

  const OktaAuth = useMemo(() => new OktaRequest(), []);

  const initApp = useCallback(async () => {
    const token = OktaAuth.getAccessToken();

    // Clear Times
    if (refreshTokenRef.current) clearInterval(refreshTokenRef.current);

    // Check Validity of token
    if (window.location.pathname.includes(ROUTES.LOGOUT)) {
      OktaAuth.logout();
    } else if (!token && !window.location.pathname.includes(ROUTES.REDIRECT)) {
      OktaAuth.login();
    } else if (window.location.pathname.includes(ROUTES.REDIRECT)) {
      if (!token) {
        if ((await OktaAuth.authenticate())?.error) {
          setIsError(true);
          wasError = true;
          window.history.replaceState({}, "", ROUTES.REDIRECT);
        }
      } else window.location.href = ROUTES.HOME;
    } else if (await OktaAuth.checkExpiration()) {
      const finalToken = OktaAuth.getAccessToken();
      if (finalToken) {
        const payload = jwtDecode(finalToken.toString()) as any;
        if (payload?.sub) trackUserLogged(payload?.sub);
      }

      setAppStarted(true);
      refreshTokenRef.current = setInterval(
        async () => await OktaAuth.checkExpiration(),
        60000, // Every minute
      );
    } else if (!token) {
      OktaAuth.login();
    }
  }, [OktaAuth]);

  const handleError = useCallback(() => OktaAuth.logout(), [OktaAuth]);

  useEffect(() => {
    if (!itDidRun) initApp();
    itDidRun = true;
  }, [initApp]);

  if (isError)
    return (
      <IonPage>
        <IonContent>
          <div className="ErrorPage">
            <h3>{t("auth_error")}</h3>
            <ButtonHighlight onClick={handleError} name={t("try_again")} />
          </div>
        </IonContent>
      </IonPage>
    );
  if (!appStarted) return <LoadingPage />;
  return <>{children}</>;
};

export default AuthProvider;
