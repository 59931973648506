import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";

type RemovePendingProductParams = {
  formId: string;
};

function RemovePendingProductReducer(
  state: ProposalState,
  { payload: { formId } }: PayloadAction<RemovePendingProductParams>,
) {
  state.productFormIds = state.productFormIds.filter((id) => id !== formId, []);
  delete state.productByFormId[formId];
}

export default RemovePendingProductReducer;
