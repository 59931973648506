import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";

type SetProductDiscountValueParams = {
  formId: string;
  value: string;
};

function SetProductDiscountValueReducer(
  state: ProposalState,
  { payload: { formId, value } }: PayloadAction<SetProductDiscountValueParams>,
) {
  state.productByFormId[formId].commercialConditions.discountValue = value;
}

export default SetProductDiscountValueReducer;
