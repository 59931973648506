import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";

type SetProductFreeUnitParams = {
  formId: string;
  value: string;
};

function SetProductFreeUnitReducer(
  state: ProposalState,
  { payload: { formId, value } }: PayloadAction<SetProductFreeUnitParams>,
) {
  state.productByFormId[
    formId
  ].commercialConditions.freePackagedProductUnitsNb = value;
}

export default SetProductFreeUnitReducer;
