import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";

type SetProductUnitParams = {
  formId: string;
  value: string;
};

function SetProductUnitReducer(
  state: ProposalState,
  { payload: { formId, value } }: PayloadAction<SetProductUnitParams>,
) {
  state.productByFormId[formId].commercialConditions.packagedProductUnitNb =
    value;
}

export default SetProductUnitReducer;
