import React from "react";

// Atoms
import { Button } from "@france/superelements/lib";

// Components
import ProfileContent from "../ProfileContent/ProfileContent";

// Styles & Config
import "./ProfileModal.scss";

export interface ProfileModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  className?: string;
}

export const ProfileModal: React.FC<ProfileModalProps> = ({
  isOpen,
  onCloseModal,
  className = "",
}) => {
  return (
    <div className={`ProfileModal ${className} ${isOpen ? "open" : "closed"}`}>
      <div className="container">
        <Button size="small" icon="close" onClick={onCloseModal} />
        <ProfileContent display="row" hasLink={true} />
      </div>
    </div>
  );
};

export default ProfileModal;
