import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";
import { MaterialConditionFormType } from "../types/materialConditionTypes";

type RemoveMaterialConditionsParams = {
  formIds: string[];
};

function RemoveMaterialConditionsReducer(
  state: ProposalState,
  { payload: { formIds } }: PayloadAction<RemoveMaterialConditionsParams>,
) {
  const updatedMaterialFormIds = state.materialFormIds.filter(
    (materialFormId) => !formIds.includes(materialFormId),
  );
  const newMaterialByFormId = state.materialByFormId;
  const updatedMaterialByFormId = Object.keys(newMaterialByFormId).reduce(
    (acc: { [formId: string]: MaterialConditionFormType }, formId) => {
      if (!formIds.includes(formId)) {
        acc[formId] = newMaterialByFormId[formId];
      }
      return acc;
    },
    {},
  );
  state.materialFormIds = updatedMaterialFormIds;
  state.materialByFormId = updatedMaterialByFormId;
}

export default RemoveMaterialConditionsReducer;
