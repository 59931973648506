import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";

type RemovePendingMaterialParams = {
  index: number;
  isPending?: boolean;
};

function RemovePendingMaterialReducer(
  state: ProposalState,
  {
    payload: { index, isPending = true },
  }: PayloadAction<RemovePendingMaterialParams>,
) {
  const formId = isPending
    ? state.pendingMaterials[index]?.formId
    : state.materialFormIds[index];

  if (isPending) {
    state.pendingMaterials.splice(index, 1);
  } else {
    state.materialFormIds.splice(index, 1);
  }

  if (formId) {
    delete state.materialByFormId[formId];
  }
}

export default RemovePendingMaterialReducer;
