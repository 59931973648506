import { isMobile } from "react-device-detect";

const windowSizeHandler = () => {
  const root = document.documentElement;
  root.style.setProperty("--screen-x", `${window.innerWidth}px`);
  root.style.setProperty("--screen-y", `${window.innerHeight}px`);
  root.style.setProperty("--window-x", `${window.screen.height}px`);
  root.style.setProperty("--window-y", `${window.screen.width}px`);

  let nb = 13;
  const size = 10 - parseInt(`${window.screen.height / 100}`, 10);

  if (isMobile || window.screen.width < 576) {
    root.style.setProperty("--rem-size", "14px");
  } else {
    root.style.setProperty("--rem-size", `${nb - size}px`);
  }
};

export default windowSizeHandler;
