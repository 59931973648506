import {
  CookiePrePath,
  deleteAllCookies,
  deleteCookie,
  getCookie,
  setCookie,
} from "./cookies";
import { TOKEN_KEY } from "@france/superelements/utils";

import PKCEToken from "./PKCE";
import isBrowser from "../isBrowser";

import axios from "axios";
import ROUTES from "../../router/constants/routes";

import { InAppBrowser } from "@capgo/inappbrowser";
import Auth0Request from "./Auth0";

const pEnv = (window as any)["env"]?.REACT_APP_GRAPHQL_URL
  ? (window as any)["env"]
  : process.env;

const minutesDiff = (firstDate: Date, secondDate: Date) => {
  let differenceValue = (secondDate.getTime() - firstDate.getTime()) / 1000;
  differenceValue /= 60;
  return differenceValue;
};

const TMP_CODE_KEY = "TMP_CODE";
const TMP_COOK = "TMP_COOK";
const OAUTH = pEnv.REACT_APP_AUTH_URL;

const TOKEN_ACCESS = TOKEN_KEY;
const TOKEN_ID = `id_${TOKEN_KEY}`;
const TOKEN_EXP = `exp_${TOKEN_KEY}`;
const TOKEN_REFRESH = `refresh_${TOKEN_KEY}`;

const ORIGIN =
  pEnv.REACT_APP_ORIGIN ||
  (window.origin.startsWith("http://192") ||
  window.origin.startsWith("http://localhost")
    ? "http://localhost:8100"
    : window.origin);

let isAuthenticating = false;
let code_verifier: string | null = null;

class OktaRequest {
  // Request Information
  private scope: string = encodeURIComponent(
    "openid offline_access profile email",
  );
  private state: string | null = null;

  private domain: string | undefined = pEnv.REACT_APP_OKTA_DOMAIN;
  private clientId: string | undefined = pEnv.REACT_APP_OKTA_CLIENT_ID;

  // Token Information
  private token = getCookie(TOKEN_ACCESS); // Access Token
  private idToken = getCookie(TOKEN_ID); // Id Token

  private exp = getCookie(TOKEN_EXP);
  private refreshToken = getCookie(TOKEN_REFRESH);
  private codes: any = null;

  // Getters
  public getAccessToken() {
    return this.token;
  }

  // Auth Methods
  public async login() {
    const PKCE = new PKCEToken();
    this.codes = await PKCE.getCodes();
    this.state = Math.random().toString(36).slice(2);

    const requestOptions = {
      client_id: this.clientId,
      response_type: "code",
      scope: this.scope,
      redirect_uri: `${ORIGIN}/callback`,
      state: this.state,
      code_challenge_method: "S256",
      code_challenge: this.codes?.code_challenge,
    };

    if (!this.codes.code_verifier) {
      return window.location.reload();
    }

    setCookie(TMP_CODE_KEY, this.codes.code_verifier);
    code_verifier = this.codes.code_verifier;

    const loginUrl = `https://${
      this.domain
    }/oauth2/v1/authorize?${Object.entries(requestOptions)
      .map((a) => `${a[0]}=${a[1]}`)
      .join("&")}`;

    setCookie(CookiePrePath, window.location.pathname);
    if (isBrowser()) {
      window.location.href = loginUrl;
    } else {
      await InAppBrowser.addListener("closeEvent", () =>
        window.location.reload(),
      );
      await InAppBrowser.addListener("urlChangeEvent", (event: any) => {
        if (event?.url) {
          if (event.url?.startsWith(requestOptions.redirect_uri)) {
            InAppBrowser.removeAllListeners();
            InAppBrowser.close();

            window.location.href = `${ROUTES.REDIRECT}?${
              event.url.split("?")[1] || ""
            }`;
          }
        }
      });

      await InAppBrowser.openWebView({ url: loginUrl, title: "MSM" });
    }
  }

  // AUTHENTICATE
  public async authenticate() {
    const params = window.location.search
      .slice(1)
      .split("&")
      .reduce((acc: any, item) => {
        const [key, ...values] = item.split("=");
        const value = values.join("=");

        acc[decodeURIComponent(key) as string] = decodeURIComponent(value);
        return acc;
      }, {});

    const prePath = getCookie(CookiePrePath);
    const nextLocation =
      !!prePath && typeof prePath === "string" ? prePath : ROUTES.HOME;
    deleteCookie(CookiePrePath);

    if (params.code) {
      const details: any = {
        code: params.code,
        scope: this.scope,
        state: params.state,
        redirect_uri: `${ORIGIN}/callback`,
        code_verifier:
          code_verifier || this.codes?.code_verifier || getCookie(TMP_CODE_KEY),
      };

      try {
        if (isAuthenticating || getCookie(TMP_COOK)) return;
        setCookie(TMP_COOK, "true");
        isAuthenticating = true;
        const res = await axios.post(`${OAUTH}/authenticate`, details);

        if (res?.data) {
          this.setAuth(res.data);
        }
        isAuthenticating = false;
        window.location.href = nextLocation;
      } catch (err) {
        console.error(err);
        deleteCookie(TMP_COOK);
        deleteCookie(TMP_CODE_KEY);
        isAuthenticating = false;
        return { error: true };
      }
    } else {
      window.location.href = nextLocation;
    }
  }

  public async checkExpiration() {
    if (this.exp) {
      const diff = minutesDiff(new Date(), new Date(Number(this.exp) * 1000));
      if (diff < 2) {
        try {
          setCookie(CookiePrePath, window.location.pathname);
          const res = await axios.post(`${OAUTH}/refreshToken`, {
            scope: this.scope,
            refresh_token: this.refreshToken,
            redirect_uri: `${ORIGIN}/callback`,
          });
          if (res?.data) {
            this.setAuth(res.data);
          }
        } catch (e) {
          console.warn(e);
          this.logout();
          return false;
        }
        return true;
      }
      return true;
    }
    this.logout();
    return false;
  }

  public async logout() {
    if (pEnv.REACT_APP_AUTH0_DOMAIN) {
      return new Auth0Request().logout();
    }

    const requestOptions = {
      id_token_hint: this.idToken || getCookie(TOKEN_ID),
      post_logout_redirect_uri: `${ORIGIN}/logout`,
    };

    let logoutUrl = `https://${this.domain}/oauth2/v1/logout?${Object.entries(
      requestOptions,
    )
      .map((a) => `${a[0]}=${a[1]}`)
      .join("&")}`;
    if (!requestOptions.id_token_hint) {
      logoutUrl = `https://${this.domain}/login/signout?${Object.entries({
        fromURI: ORIGIN,
      })
        .map((a) => `${a[0]}=${a[1]}`)
        .join("&")}`;
    }

    if (isBrowser()) {
      this.clearAuth();
      setCookie(CookiePrePath, window.location.pathname);
      window.location.href = logoutUrl;
    } else {
      await InAppBrowser.addListener("closeEvent", () =>
        window.location.reload(),
      );
      await InAppBrowser.addListener("urlChangeEvent", (event: any) => {
        if (event?.url) {
          if (
            event.url?.startsWith(requestOptions.post_logout_redirect_uri) ||
            event?.url.startsWith(`https://${this.domain}/oauth2/v1/authorize`)
          ) {
            InAppBrowser.removeAllListeners();
            InAppBrowser.close();
            this.clearAuth();
            setCookie(CookiePrePath, window.location.pathname);
            window.location.reload();
          }
        }
      });

      await InAppBrowser.openWebView({ url: logoutUrl, title: "Logout" });
    }
  }

  public setAuth(params: {
    access_token: string;
    id_token: string;
    expires_in: number;
    refresh_token: string;
  }) {
    // Access Token
    this.token = params.access_token;
    setCookie(TOKEN_ACCESS, params.access_token);
    // Id Token
    this.idToken = params.id_token;
    setCookie(TOKEN_ID, params.id_token);
    // Exp
    this.exp = (params.expires_in + Date.now() / 1000).toString();
    setCookie(TOKEN_EXP, this.exp?.toString());
    // Refresh
    this.refreshToken = params.refresh_token;
    setCookie(TOKEN_REFRESH, params.refresh_token);
  }

  public clearAuth() {
    deleteAllCookies();
    localStorage.clear();
    this.token = null;
    this.idToken = null;
    this.refreshToken = null;
    this.exp = null;
  }
}

export default OktaRequest;
