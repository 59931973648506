import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";
import { PackagedBeerDetailsContainer } from "../../../../gql/hooks";

type SetProductContainerParams = {
  formId: string;
  value: PackagedBeerDetailsContainer;
};

function SetProductContainerReducer(
  state: ProposalState,
  { payload: { formId, value } }: PayloadAction<SetProductContainerParams>,
) {
  state.productByFormId[formId].isPending = false;
  state.productByFormId[formId].commercialConditions.container = value;
  // Reset the chosen packagedProductId
  state.productByFormId[formId].commercialConditions.packagedProductId =
    undefined;
}

export default SetProductContainerReducer;
