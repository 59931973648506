import { useCallback } from "react";
import { useIonRouter } from "@ionic/react";
import { useLocalize } from "@france/superelements/utils";

import { Icon } from "@france/superelements/lib";
import { FooterConfigType } from "../../../constants/footerConfig";

const DesktopMenuItem: React.FC<FooterConfigType> = ({
  icon,
  path,
  disabled,
  label,
}) => {
  const t = useLocalize();
  const { routeInfo, push } = useIonRouter();

  const handleClick = useCallback(() => path && push(path), [path, push]);

  return (
    <button
      id={label}
      onClick={handleClick}
      role="menuitem"
      className={routeInfo.pathname.includes(path!) ? "selected" : undefined}
    >
      <Icon size="large" icon={icon} disabled={disabled} />
      <span style={{ opacity: disabled ? "0.5" : undefined }}>{t(label)}</span>
    </button>
  );
};

export default DesktopMenuItem;
