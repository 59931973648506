import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";
import { ProductCategory } from "../../../../gql/hooks";

type SetPendingMaterialCategoryParams = {
  index: number;
  category: ProductCategory;
};

function SetPendingMaterialCategoryReducer(
  state: ProposalState,
  {
    payload: { index, category },
  }: PayloadAction<SetPendingMaterialCategoryParams>,
) {
  state.pendingMaterials[index].commercialConditions.category = category;
}

export default SetPendingMaterialCategoryReducer;
