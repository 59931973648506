import { registerLocale } from "react-datepicker";
import { es as date_es, enUS as date_en } from "date-fns/locale";
import en from "./en.json";
import es from "./es.json";

const locales = {
  en,
  es,
};

// Register locales date
registerLocale("es", date_es);
registerLocale("en", date_en);

export const AVAILABLE_LANG = Object.keys(locales);

export default locales;
