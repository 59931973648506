import { InMemoryCache, Reference } from "@apollo/client";
import {
  FieldPolicy,
  KeyArgsFunction,
  KeySpecifier,
} from "@apollo/client/cache/inmemory/policies";

function skipLimitPagination<T extends Reference>(
  keyArgs: KeyArgsFunction | KeySpecifier | false | undefined = false,
): FieldPolicy<T[]> {
  return {
    keyArgs,
    merge(existing = [], incoming, { args }) {
      if (!args?.skip) {
        return incoming;
      }
      const toAdd = incoming.filter(
        (inc: Reference) =>
          !existing.some((e: Reference) => e.__ref === inc.__ref),
      );
      return [...existing, ...toAdd];
    },
  };
}

const cache = new InMemoryCache({
  typePolicies: {
    Slide: {
      keyFields: ["id", "slideImageId"],
    },
    Query: {
      fields: {
        pointOfSales: skipLimitPagination(["where", "isOwner"]),
        locations: skipLimitPagination(["where", "isOwner"]),
        proposals: skipLimitPagination(["pointOfSaleId"]),
      },
    },
  },
});

export default cache;
