import { PackagedBeerDetailsContainer } from "../../../gql/hooks";
import { ProductConditionFormType } from "./types/productConditionTypes";

export function sortProductFormByContainer(productByFormId: {
  [formId: string]: ProductConditionFormType;
}) {
  const productArray = Object.values(productByFormId);

  productArray.sort(
    (a: ProductConditionFormType, b: ProductConditionFormType) => {
      const bottleType = a.commercialConditions
        .container as PackagedBeerDetailsContainer;
      const kegType = b.commercialConditions
        .container as PackagedBeerDetailsContainer;

      if (kegType && bottleType) return kegType.localeCompare(bottleType);
      return 0;
    },
  );

  return productArray.reduce<{
    [formId: string]: ProductConditionFormType;
  }>(
    (
      acc: { [formId: string]: ProductConditionFormType },
      product: ProductConditionFormType,
    ) => {
      acc[product.formId] = product;
      return acc;
    },
    {},
  );
}
