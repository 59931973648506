import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";
import { constructMaterialsCommercialCondition } from "../../../../utils/commercialConditions/constructMaterialCondition";
import {
  MaterialConditionFormType,
  MaterialConditionType,
} from "../types/materialConditionTypes";

type AddMaterialConditionsParams = {
  materialIds: number[];
  commercialValues: MaterialConditionType[];
};

function AddMaterialConditionsReducer(
  state: ProposalState,
  {
    payload: { materialIds, commercialValues },
  }: PayloadAction<AddMaterialConditionsParams>,
) {
  const formattedMaterialConditions =
    constructMaterialsCommercialCondition(materialIds);
  formattedMaterialConditions.forEach((form) => {
    const { productId, formId } = form;
    if (!productId) return;
    const commercialValue = commercialValues?.find(
      (_) => _.productId === productId,
    );

    const materialCondition: MaterialConditionFormType = {
      formId,
      productId,
      commercialConditions: !!commercialValue
        ? {
            packagedProductId: commercialValue.packagedProductId,
            packagedProductUnitNb: String(
              commercialValue.packagedProductUnitNb || "",
            ),
          }
        : {},
    };
    state.materialByFormId[formId] = materialCondition;
    const newFormIds = Object.keys({ ...state.materialByFormId });
    state.materialFormIds = newFormIds;
  });
}

export default AddMaterialConditionsReducer;
