import * as Detect from "react-device-detect";

export const isBrowser = () =>
  Detect.isChrome ||
  Detect.isFirefox ||
  Detect.isSafari ||
  Detect.isOpera ||
  Detect.isIE ||
  Detect.isEdge ||
  Detect.isYandex ||
  Detect.isChromium ||
  (Detect.isMobileSafari && !Detect.isTablet) ||
  Detect.isSamsungBrowser;

export default isBrowser;
