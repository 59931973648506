import { UserPicture } from "@france/superelements/lib";
import { Link } from "react-router-dom";
import ROUTES from "../../../../router/constants/routes";

import "./ProfilePicture.scss";
import { UserFragment } from "../../../../gql/hooks";

export interface ProfilePictureProps {
  picSize?: "small" | "medium" | "large" | "largeL" | "largeXL";
  hasLink?: boolean;
  user?: UserFragment;
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({
  user,
  picSize,
  hasLink,
}) => {
  if (hasLink)
    return (
      <div className="profilePicture">
        <div className="bouncer"></div>
        <Link to={ROUTES.PROFILE}>
          <UserPicture
            size={picSize}
            firstName={user?.firstName}
            lastName={user?.lastName}
            userPicture={user?.profilePicture}
          />
        </Link>
      </div>
    );
  return (
    <UserPicture
      size={picSize}
      firstName={user?.firstName}
      lastName={user?.lastName}
      userPicture={user?.profilePicture}
    />
  );
};

export default ProfilePicture;
