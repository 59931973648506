import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";

type SetMaterialConditionUnitParams = {
  formId: string;
  value: string;
};

function SetMaterialConditionUnitReducer(
  state: ProposalState,
  { payload: { formId, value } }: PayloadAction<SetMaterialConditionUnitParams>,
) {
  state.materialByFormId[formId].commercialConditions.packagedProductUnitNb =
    value;
}

export default SetMaterialConditionUnitReducer;
