import { IonContent, IonPage } from "@ionic/react";
import SimpleMahouLoader from "../../components/SimpleMahouLoader/SimpleMahouLoader";

const LoadingPage: React.FC<unknown> = () => {
  return (
    <IonPage id="LoadingPage">
      <IonContent>
        <SimpleMahouLoader />
      </IonContent>
    </IonPage>
  );
};

export default LoadingPage;
