import React, { useCallback, useMemo } from "react";

// Atoms
import { ButtonHighlight } from "@france/superelements/lib";

// Components
import ProfileDetail from "./partials/ProfileDetail";
import ProfilePicture from "../../pages/Profile/partials/ProfilePicture/ProfilePicture";

// Hooks
import { useGetMeQuery, useGetReportingQuery } from "../../gql/hooks";
import { getFullName, useLocalize } from "@france/superelements/utils";

// Styles & Utils
import "./ProfileContent.scss";
import ROUTES from "../../router/constants/routes";
import { setCookie } from "@france/superelements/utils/security/cookies";
import { CookiePrePath } from "../../utils/security/cookies";

export interface ProfileContentProps {
  className?: string;
  display?: "row" | "col";
  hasLink?: boolean;
}

export const ProfileContent: React.FC<ProfileContentProps> = ({
  className = "",
  display = "col",
  hasLink,
}) => {
  const t = useLocalize();

  const { data: user } = useGetMeQuery();
  const { data } = useGetReportingQuery();

  // Memos
  const fullName = useMemo(
    () => getFullName(user?.me.firstName, user?.me.lastName),
    [user],
  );

  // Handlers
  const handleCommentReport = useCallback(
    () =>
      data?.reporting?.url?.length
        ? window.open(data?.reporting?.url, "_system", "location=yes")
        : undefined,
    [data?.reporting?.url],
  );

  const handleClick = useCallback(() => {
    setCookie(CookiePrePath, window.location.pathname);
    window.location.href = ROUTES.LOGOUT;
  }, []);

  return (
    <div className={`ProfileContent ${className}`}>
      <div className="mainContent">
        <div className={`profileContainer ${display}`}>
          <ProfilePicture
            picSize={"largeXL"}
            user={user?.me}
            hasLink={hasLink}
          />
          <div className="informations">
            {!!fullName && <h1>{fullName}</h1>}
            <div className="details">
              <ProfileDetail value={user?.me.phoneNumber} icon="phone" />
              <ProfileDetail value={user?.me.email} icon="mail" />
            </div>
          </div>
        </div>
        <ButtonHighlight
          disabled={!data?.reporting?.url?.length}
          className="commentReport"
          name={t("commentReport")}
          onClick={handleCommentReport}
          type="secondary"
        />
      </div>
      <ButtonHighlight
        name={t("logout")}
        onClick={handleClick}
        type="secondary"
        leftIcon="logout"
      />
    </div>
  );
};

export default ProfileContent;
