import { useEffect, useState } from "react";
import { IonReactRouter } from "@ionic/react-router";
import { IonApp, setupIonicReact } from "@ionic/react";
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";

import { pdfjs } from "react-pdf";
import isBrowser from "./utils/isBrowser";
import Router from "./router/Router/Router";
import useMobileSize from "./utils/commercialConditions/useMobileSize";
import LocalizeProvider from "./router/components/LocalizeProvider/LocalizeProvider";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "./services/applicationInsight";

/* Theme variables */
import "./theme/global.scss";

/* Swiper CSS */
import "swiper/css";
import { store } from "./redux/index";
import { Provider } from "react-redux";
import initApollo from "./services/apollo";
import { TransitionProvider } from "./contexts/TransitionContext";
import AuthChecker from "./router/components/AuthChecker/AuthChecker";
import SimpleMahouLoader from "./components/SimpleMahouLoader/SimpleMahouLoader";

/* PDFJS */
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import windowSizeHandler from "./utils/windowSizeHandler";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

// Mutin DND Debugging messages
(window["__react-beautiful-dnd-disable-dev-warnings" as any] as any) = true;

// App Setup
setupIonicReact();
if (!isBrowser()) (window.screen.orientation as any)?.lock?.("portrait");

// App
const App: React.FC = () => {
  useMobileSize();
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    initApollo(setClient).catch(console.error);

    windowSizeHandler();
    window.addEventListener("resize", windowSizeHandler);

    return () => {
      window.removeEventListener("resize", windowSizeHandler);
    };
  }, []);

  if (!client) return <SimpleMahouLoader />;
  return (
    <AuthChecker>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <LocalizeProvider>
            <TransitionProvider>
              <IonApp>
                <IonReactRouter>
                  <Router />
                </IonReactRouter>
              </IonApp>
            </TransitionProvider>
          </LocalizeProvider>
        </Provider>
      </ApolloProvider>
    </AuthChecker>
  );
};

export default App;
