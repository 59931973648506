import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";

type SetPendingMaterialUnitParams = {
  index: number;
  unitNb: string;
};

function SetPendingMaterialUnitReducer(
  state: ProposalState,
  { payload: { index, unitNb } }: PayloadAction<SetPendingMaterialUnitParams>,
) {
  state.pendingMaterials[index].commercialConditions.packagedProductUnitNb =
    unitNb;
}

export default SetPendingMaterialUnitReducer;
