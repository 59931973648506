import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";

type SetProductDiscountXParams = {
  formId: string;
  value: string;
};

function SetProductDiscountXReducer(
  state: ProposalState,
  { payload: { formId, value } }: PayloadAction<SetProductDiscountXParams>,
) {
  state.productByFormId[formId].commercialConditions.discountX = value;
}

export default SetProductDiscountXReducer;
