import CryptoJS from "crypto-js";
import isBrowser from "../isBrowser";

class PKCEToken {
  public verifier: string | null = null;
  public challenger: string | null = null;

  constructor() {
    this.getVerifier();
    if (this.verifier) {
      this.getChallenger(this.verifier);
    }
  }

  // Verifier Methods
  private dec2hex(dec: number) {
    return ("0" + dec.toString(16)).substr(-2);
  }

  private generateRandomString() {
    var array = new Uint32Array(56 / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, this.dec2hex).join("");
  }

  private getVerifier() {
    if (!this.verifier) this.verifier = this.generateRandomString();
    return this.verifier;
  }

  // Challenger Methods
  private sha256(plain: string) {
    if (isBrowser()) {
      const encoder = new TextEncoder();
      const data = encoder.encode(plain);
      // Returns promise ArrayBuffer
      return window.crypto.subtle.digest("SHA-256", data);
    } else {
      const hash = CryptoJS.SHA256(plain);
      const hashHex = hash.toString(CryptoJS.enc.Hex);
      const buffer = new Uint8Array(hashHex.length / 2);
      for (let i = 0; i < hashHex.length; i += 2) {
        buffer[i / 2] = parseInt(hashHex.substr(i, 2), 16);
      }

      return buffer.buffer;
    }
  }

  private base64urlEncode(a: ArrayBuffer) {
    var str = "";
    var bytes = new Uint8Array(a as ArrayBuffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      str += String.fromCharCode(bytes[i]);
    }
    return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
  }

  public async getChallenger(verifier: string) {
    if (!this.challenger) {
      const hashed = await this.sha256(verifier);
      this.challenger = this.base64urlEncode(hashed);
    }
    return this.challenger;
  }

  // Codes generators
  public async getCodes() {
    await this.getVerifier();
    if (this.verifier) await this.getChallenger(this.verifier);

    return {
      code_verifier: this.verifier,
      code_challenge: this.challenger,
    };
  }

  // Clear codes
  public clearCodes() {
    this.verifier = null;
    this.challenger = null;
  }
}

export default PKCEToken;
