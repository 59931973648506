import dayjs from "dayjs";

export const formatDateTwoDigits = (date: Date, lang: string = "es") =>
  dayjs(date).locale(lang).format("DD/MM/YY");

export const formatDateFourDigits = (date: Date, lang: string = "es") =>
  dayjs(date).locale(lang).format("DD/MM/YYYY");

export const formatFullDate = (date: Date, lang: string = "es") =>
  dayjs(date).locale(lang).format("DD MMMM YYYY");

export const formatDisplayDate = (date: Date, lang: string = "es") =>
  dayjs(date).locale(lang).format("DD MMM");
