import React, { useCallback, useEffect } from "react";
import { App, URLOpenListenerEvent } from "@capacitor/app";

/*
  Current possible Urls:
    - m360plus.es
    - app.msm.southpigalle.io
    - app.dev.msm.southpigalle.io
    - app.preprod.msm.southpigalle.io
*/

const AppUrlListener: React.FC<unknown> = () => {
  const handleAppOpener = useCallback((event: URLOpenListenerEvent) => {
    try {
      const slug = event.url.replace("https://", "").split("/");
      slug.shift();
      const url = slug.join("/");

      if (slug) {
        window.location.pathname = `/${url}`;
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    App.addListener("appUrlOpen", handleAppOpener);
    return () => {
      App.removeAllListeners();
    };
  }, [handleAppOpener]);

  return null;
};

export default AppUrlListener;
