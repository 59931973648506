import "./SimpleMahouLoader.scss";

interface SimpleMahouLoaderProps {
  className?: string;
  style?: React.CSSProperties;
  background?: boolean;
}

const SimpleMahouLoader: React.FC<SimpleMahouLoaderProps> = ({
  className,
  style,
  background,
}) =>
  background ? (
    <div className="simpleMahouLoaderBackground">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`simpleMahouLoaderWithBackground ${
          className ? className : ""
        }`}
        style={style}
        viewBox="0 0 56.2 40.8"
      >
        <path d="m38.301 23.776-.009-11.881c0-3.004-2.112-3.438-4.38-3.438v31.806H22.164S22.1 16.21 22.1 11.896c0-2.968-1.872-3.614-4.369-3.614l-.01 31.983H5.838c.084-4.053 0-24.228 0-26.61C5.838 6.56 0 7.103 0 7.103L8.095 0h3.082c1.837 0 6.554 1.427 6.554 5.612L24.136 0h3.234c2.589 0 6.542 1.662 6.542 5.741L40.456 0h3.055c2.338 0 6.553 1.408 6.553 5.726l.116 18.05.002 3.131c0 7.094 5.843 6.553 5.843 6.553l-8.091 7.104c-.815.716-9.642-.14-9.642-9.005l.009-7.783z" />
      </svg>
    </div>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`simpleMahouLoader ${className ? className : ""}`}
      style={style}
      viewBox="0 0 56.2 40.8"
    >
      <path d="m38.301 23.776-.009-11.881c0-3.004-2.112-3.438-4.38-3.438v31.806H22.164S22.1 16.21 22.1 11.896c0-2.968-1.872-3.614-4.369-3.614l-.01 31.983H5.838c.084-4.053 0-24.228 0-26.61C5.838 6.56 0 7.103 0 7.103L8.095 0h3.082c1.837 0 6.554 1.427 6.554 5.612L24.136 0h3.234c2.589 0 6.542 1.662 6.542 5.741L40.456 0h3.055c2.338 0 6.553 1.408 6.553 5.726l.116 18.05.002 3.131c0 7.094 5.843 6.553 5.843 6.553l-8.091 7.104c-.815.716-9.642-.14-9.642-9.005l.009-7.783z" />
    </svg>
  );

export default SimpleMahouLoader;
