import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";
import { RecallType } from "../../../../gql/hooks";

type SetProductAmortizationTypeParams = {
  formId: string;
  amortizationType: RecallType;
};

function SetProductAmortizationTypeReducer(
  state: ProposalState,
  {
    payload: { formId, amortizationType },
  }: PayloadAction<SetProductAmortizationTypeParams>,
) {
  state.productByFormId[formId].commercialConditions.amortizationType =
    amortizationType;
  // Reset the value of the previous recall as format may be different
  state.productByFormId[formId].commercialConditions.amortizationValue = "";
  const productByformId = state.productByFormId;
  const currentProduct = productByformId[formId];
  const currentContainer = currentProduct.commercialConditions.container;
  if (!currentContainer) return;
  const firstProductOfSameContainer = Object.values(productByformId).find(
    (p) => p.commercialConditions.container === currentContainer,
  );
  if (firstProductOfSameContainer?.formId === formId) {
    Object.values(productByformId)
      .filter(
        ({ commercialConditions }) =>
          commercialConditions.container === currentContainer,
      )
      .filter(
        ({ commercialConditions }) => !commercialConditions.amortizationValue,
      )
      .forEach(({ formId }) => {
        state.productByFormId[formId].commercialConditions["amortizationType"] =
          amortizationType;
      });
  }
}

export default SetProductAmortizationTypeReducer;
