import { useCallback, useState } from "react";
import { useIonRouter } from "@ionic/react";

import { Icon } from "@france/superelements/lib";

import ROUTES from "../../../constants/routes";
import ProposalDraftModal from "../../../../components/ProposalDraftModal/ProposalDraftModal";

const NewProposalButton: React.FC<unknown> = () => {
  const { push, routeInfo } = useIonRouter();
  const [posId, setPosId] = useState<number>(0);

  // Hanlders
  const handleGoPosCP = useCallback(() => {
    if (
      routeInfo.pathname.startsWith(ROUTES.POS) &&
      !!routeInfo.pathname.replace(ROUTES.POS, "").replace("/", "")
    ) {
      setPosId(
        Number(routeInfo.pathname.replace(ROUTES.POS, "").replace("/", "")),
      );
    } else {
      localStorage.removeItem("proposalId");
      localStorage.removeItem("selectedPosId");
      localStorage.setItem("reachableCreationStep", "0");
      push(ROUTES.SELECT_POS);
    }
  }, [push, routeInfo.pathname]);

  const handleCloseProposal = useCallback(() => setPosId(0), []);

  return (
    <>
      <ProposalDraftModal
        posId={posId}
        openStatus={!!posId}
        onClose={handleCloseProposal}
        redirect
      />
      <button className="ButtonHighlight primary " onClick={handleGoPosCP}>
        <Icon icon="plus-square" />
      </button>
    </>
  );
};

export default NewProposalButton;
