import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";

type SetMaterialPackagedIdParams = {
  formId: string;
  value: number;
  price?: number | null;
};

function SetMaterialPackagedIdReducer(
  state: ProposalState,
  {
    payload: { formId, value, price },
  }: PayloadAction<SetMaterialPackagedIdParams>,
) {
  state.materialByFormId[formId].commercialConditions.price =
    price || undefined;
  state.materialByFormId[formId].commercialConditions.packagedProductId = value;
}

export default SetMaterialPackagedIdReducer;
